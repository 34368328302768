import React, { useEffect, useRef, useState } from "react"
import { useQuery, useMutation } from "@apollo/client"
import { DataTable } from "primereact/datatable"
import { Column } from "primereact/column"
import { InputText } from "primereact/inputtext"
import { Toolbar } from "primereact/toolbar"
import { Toast } from "primereact/toast"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { InputTextarea } from "primereact/inputtextarea"
import { Checkbox } from "primereact/checkbox"
import { PrimeIcons } from "primereact/api" // Import icons

import { IconField } from "primereact/iconfield"
import { InputIcon } from "primereact/inputicon"
import styles from "./editView.module.css"
import "./editView.css"
import { getdatalist } from "../utls/getEditList"
import { EditListNames } from "../utls/EditListNames"
import { FileUpload } from "primereact/fileupload"
import moment from "moment-timezone"
import useSubmitForm from "../../../hooks/useSubmitForm"

const EditView = ({ editListName }) => {
  const dataListInfo = getdatalist(editListName) //get the data list info
  const { create, update, query, deleteRecord, deleteRecords, listName, fields, getEmptyList } = dataListInfo

  let emptyList = getEmptyList()
  const { loading, error, data, refetch } = useQuery(query)
  const [updateData] = useMutation(update)
  const [createData] = useMutation(create)
  const [deleteData] = useMutation(deleteRecord)
  const [deleteMultipleData] = useMutation(deleteRecords)

  const [dataList, setDataList] = useState([])

  const [globalFilter, setGlobalFilter] = useState(null)
  const [recordDialog, setRecordDialog] = useState(false)
  const [rowData, setRowData] = useState(emptyList)
  const [selectedProducts, setSelectedProducts] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const [deleteProductDialog, setDeleteProductDialog] = useState(false)
  const [deleteProductsDialog, setDeleteProductsDialog] = useState(false)
  const [tempThumbnail, setTempThumbnail] = useState(null)
  const [tempBanner, setTempBanner] = useState(null)
  const [updatedFields, setUpdatedFields] = useState({})
  //error msg for dialog
  const [errorMsg, setErrorMsg] = useState(null)

  const dt = useRef(null)
  const toast = useRef(null)
  const { submitForm } = useSubmitForm()

  //check if user is superadmin from localStorage
  const isSuperAdmin = localStorage.getItem("isSuperAdmin")

  useEffect(() => {
    if (data) {
      switch (editListName) {
        case EditListNames.POSTS:
          setDataList(data[EditListNames.POSTS])
          break
        case EditListNames.MENUS:
          setDataList(data[EditListNames.MENUS])
          break
        case EditListNames.LOAN_PRODUCTS:
          setDataList(data[EditListNames.LOAN_PRODUCTS])
          break
        case EditListNames.HOMES:
          setDataList(data[EditListNames.HOMES])
          break
        case EditListNames.ABOUTS:
          setDataList(data[EditListNames.ABOUTS])
          break
        case EditListNames.LARGE_AMOUNTS:
          setDataList(data[EditListNames.LARGE_AMOUNTS])
          break
        case EditListNames.PERSONAL_LOANS:
          setDataList(data[EditListNames.PERSONAL_LOANS])
          break
        case EditListNames.PROPERTY_LOANS:
          setDataList(data[EditListNames.PROPERTY_LOANS])
          break
        case EditListNames.QAS:
          setDataList(data[EditListNames.QAS])
          break
        case EditListNames.METAS:
          setDataList(data[EditListNames.METAS])
          break
        case EditListNames.USERS:
          setDataList(data[EditListNames.USERS])
          break

        case EditListNames.APPLICATION_FORMS:
          setDataList(data[EditListNames.APPLICATION_FORMS])
          break
        case EditListNames.APPLICATION_FORM_REGISTERS:
          setDataList(data[EditListNames.APPLICATION_FORMS])
          break
        default:
          break
      }
    }
  }, [data, editListName])

  useEffect(() => {
    refetch()
  }, [editListName])

  const bodyTemplate = (rowData, field) => {
    const fieldParts = field.split(".")
    let value = rowData
    fieldParts.forEach((part) => {
      value = value ? value[part] : null
    })

    if (field === "password") {
      return "********"
    }
    if (field === "tags") {
      return Array.isArray(value) ? value.map((tag) => tag.name).join(", ") : ""
    }

    if (field === "createdAt" || field === "updatedAt") {
      if (!value) {
        return ""
      }
      return moment(value).tz("Asia/Hong_Kong").format("YYYY-MM-DD HH:mm:ss")
    }
    if (field === "finished") {
      return value ? <i className="pi pi-check" style={{ color: "green" }}></i> : <i className="pi pi-times" style={{ color: "red" }}></i>
    }

    return value && value.length > 20 ? `${value.substring(0, 20)}...` : value
  }

  const renderHeader = () => {
    return (
      <div className={styles.header_container}>
        <div className={`${styles.header_title}`}>
          <h2>{listName}</h2>
        </div>
        <div className={`${styles.search_wrapper} `}>
          <IconField iconPosition="left" className={styles.searchField}>
            <InputIcon>
              <i className="pi pi-search" style={{ verticalAlign: "top" }} />
            </InputIcon>
            <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
          </IconField>
        </div>
      </div>
    )
  }

  const openNew = () => {
    setRowData(emptyList)
    setSubmitted(false)
    setRecordDialog(true)
    setErrorMsg(null)
  }

  const handleCancel = () => {
    setRecordDialog(false)
    setRowData(emptyList)
    setTempThumbnail(null)
    setTempBanner(null)
    setErrorMsg(null)
  }

  const hideDialog = () => {
    setSubmitted(false)
    setRecordDialog(false)
    setErrorMsg(null)
  }
  const saveRecord = async () => {
    setSubmitted(true)

    try {
      let rs
      const { id, __typename, tagsCount, ...data } = rowData
      const _rowData = {}

      if (editListName === EditListNames.APPLICATION_FORMS) {
        const requiredFields = ["name", "phone", "amount", "paymentPeriod", "paymentMethod", "salary", "idNumber"]
        for (const field of requiredFields) {
          if (!data[field]) {
            setErrorMsg(`${field} is empty or null`)
            return
          }
        }
      }

      Object.entries(updatedFields).forEach(([key, value]) => {
        if (key === "tags") {
          if (value) {
            _rowData[key] = {
              connect: Array.isArray(value) ? value.map((tag) => ({ id: tag.id })) : [{ id: value.id }],
            }
          }
        } else if (key === "author") {
          if (value) {
            _rowData[key] = {
              connect: value ? { id: value.id } : null,
            }
          }
        } else if (key === "thumbnail" && tempThumbnail) {
          _rowData[key] = { upload: tempThumbnail.file }
        } else if (key === "banner" && tempBanner) {
          _rowData[key] = { upload: tempBanner.file }
        } else if (typeof value === "object" && value !== null && !Array.isArray(value)) {
          const nestedObject = {}
          Object.entries(value).forEach(([nestedKey, nestedValue]) => {
            if (nestedKey !== "__typename") {
              nestedObject[nestedKey] = nestedValue
            }
          })
          _rowData[key] = nestedObject
        } else if (!(key === "thumbnail" || key === "banner")) {
          _rowData[key] = value
        }
      })

      console.log("Updated fields data:", _rowData)

      if (id) {
        rs = await updateData({
          variables: {
            where: { id },
            data: _rowData,
          },
        })

        if (rs) {
          setDataList((prevDataList) => prevDataList.map((item) => (item.id === id ? { ...item, ...rowData } : item)))
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Record Updated",
            life: 3000,
          })
        }
      } else {
        rs = await createData({
          variables: {
            data: rowData,
          },
        })

        //if editListName is ApplicationForms, then we need to update the crm
        if (editListName === EditListNames.APPLICATION_FORMS) {
          const formattedValues = {
            ...rowData,
            // workDuration: rowData.workDuration
            //   ? moment(rowData.workDuration).format("YYYY-MM-DD")
            //   : null,
          }
          if (formattedValues) {
            const response = await submitForm(formattedValues) //submit to crm
          }
        }

        if (rs && rs.data) {
          const operationName = Object.keys(rs.data)[0]
          const newId = rs.data[operationName].id
          const newRecord = { ...rowData, id: newId }

          setDataList((prevDataList) => [...prevDataList, newRecord])
          toast.current.show({
            severity: "success",
            summary: "Successful",
            detail: "Record Added",
            life: 3000,
          })
        }
      }
      setRecordDialog(false)
      setRowData(emptyList)
      setTempThumbnail(null)
      setTempBanner(null)
      setUpdatedFields({}) // Reset updated fields
      refetch()
    } catch (error) {
      console.error("Error saving or updating item:", error)
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Failed to save or update the record.",
        life: 3000,
      })
    }
  }

  const handleInputChange = (e, field) => {
    let { value } = e.target
    if (field === "tags") {
      value = value.split(",").map((tag) => ({ id: tag.trim() }))
    }
    if (field === "isAdmin" || field === "showCMS" || field === "showEsignature") {
      value = e.checked
    }

    const fieldParts = field.split(".")
    if (fieldParts.length > 1) {
      const [parentField, childField] = fieldParts
      setRowData((prevState) => ({
        ...prevState,
        [parentField]: {
          ...prevState[parentField],
          [childField]: value,
        },
      }))
    } else {
      setRowData({ ...rowData, [field]: value })
    }

    setUpdatedFields((prevState) => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleFileUpload = (event, field) => {
    const file = event.files[0]
    const objectURL = URL.createObjectURL(file)

    if (field === "thumbnail") {
      setTempThumbnail({ file, objectURL })
      setUpdatedFields((prevState) => ({
        ...prevState,
        [field]: { upload: file },
      }))
    } else if (field === "banner") {
      setTempBanner({ file, objectURL })
      setUpdatedFields((prevState) => ({
        ...prevState,
        [field]: { upload: file },
      }))
    }
  }

  const header = renderHeader()

  // const exportCSV = () => {
  //   dt.current.exportCSV()
  // }

  const exportCSV = () => {
    // Step 1: Transform the data
    const modifiedDataList = filteredDataList.map((row) => {
      let newRow = { ...row }

      // Convert the 'finished' field to '✓' or '✗' (not exporting it)
      if (newRow.finished !== undefined) {
        newRow.finished = newRow.finished ? "Completed" : "Not Completed"
      }

      return newRow
    })

    // Step 2: Convert modified data to CSV format
    const csvContent = convertToCSV(modifiedDataList)

    // Step 3: Trigger a download of the CSV file
    downloadCSV(csvContent, "exported-data.csv")
  }

  // Utility function to convert JSON to CSV, remove columns, and reorder columns
  const convertToCSV = (data) => {
    if (data.length === 0) return ""

    // Define the mapping of English column names to Chinese
    const columnMapping = {
      application_no: "申請NO",
      referenceNo: "申請NO",
      date: "日期",
      name: "姓名",
      idNumber: "ID",
      phone: "電話",
      amount: "金額",
      term: "期數",
      paymentPeriod: "期數",
      paymentMethod: "出糧方法",
      comname: "公司名",
      salary: "薪金",
      createdAt: "創建日期",
      updatedAt: "更新日期",
    }

    // Get all headers from the first row of data
    let headers = Object.keys(data[0])

    // Remove '__typename', 'id', and 'finished' fields
    headers = headers.filter((header) => !["__typename", "id", "finished"].includes(header))

    // Replace English headers with Chinese headers
    const chineseHeaders = headers.map((header) => columnMapping[header] || header)

    // Map each row of data to a CSV string, joining fields by commas
    const rows = data.map((row) =>
      headers
        .map((fieldName) => {
          const value = row[fieldName]
          return `"${value || ""}"` // Handle empty values
        })
        .join(",")
    )

    // Join headers and rows into a complete CSV string
    return [chineseHeaders.join(","), ...rows].join("\r\n")
  }

  // Utility function to trigger a CSV file download with UTF-8 BOM
  const downloadCSV = (csvContent, fileName) => {
    const BOM = "\uFEFF" // UTF-8 BOM
    const blob = new Blob([BOM + csvContent], {
      type: "text/csv;charset=utf-8;",
    })
    const link = document.createElement("a")
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", fileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }

  const confirmDeleteSelected = () => {
    setDeleteProductsDialog(true)
  }

  const editProduct = (rowData) => {
    setRowData({ ...rowData })
    setRecordDialog(true)
  }

  const confirmDeleteProduct = (rowData) => {
    setRowData(rowData)
    setDeleteProductDialog(true)
  }

  const deleteProduct = async () => {
    try {
      const rs = await deleteData({
        variables: {
          where: { id: rowData.id },
        },
      })

      if (rs) {
        let _products = dataList.filter((val) => val.id !== rowData.id)
        setDataList(_products)
        setDeleteProductDialog(false)
        setRowData(emptyList)
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Product Deleted",
          life: 3000,
        })
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete the record.",
          life: 3000,
        })
      }
      refetch()
    } catch (error) {
      console.error("Error deleting item:", error)
    }
  }
  const deleteSelectedProducts = async () => {
    try {
      const selectedIds = selectedProducts.map((val) => ({ id: val.id }))
      console.log("Selected IDs to delete:", selectedIds)
      const rs = await deleteMultipleData({
        variables: {
          where: selectedIds,
        },
      })
      if (rs) {
        let _products = dataList.filter((val) => !selectedProducts.includes(val))
        setDataList(_products)
        setDeleteProductsDialog(false)
        setSelectedProducts(null)
        toast.current.show({
          severity: "success",
          summary: "Successful",
          detail: "Products Deleted",
          life: 3000,
        })
      } else {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: "Failed to delete the record.",
          life: 3000,
        })
      }
    } catch (error) {
      console.error("Error deleting item:", error)
    }
    refetch()
  }

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false)
  }

  const hideDeleteProductsDialog = () => {
    setDeleteProductsDialog(false)
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className={`mr-2 ${styles.circleBtn}`} onClick={() => editProduct(rowData)} />
        <Button
          icon="pi pi-trash"
          rounded
          outlined
          severity="danger"
          className={`${styles.circleBtn}`}
          onClick={() => confirmDeleteProduct(rowData)}
        />
      </React.Fragment>
    )
  }

  const deleteProductDialogFooter = (
    <React.Fragment>
      <Button className={`${styles.actionBtn} me-2`} label="No" icon="pi pi-times" outlined onClick={hideDeleteProductDialog} />
      <Button className={`${styles.actionBtn}`} label="Yes" icon="pi pi-check" severity="danger" onClick={deleteProduct} />
    </React.Fragment>
  )

  const deleteProductsDialogFooter = (
    <React.Fragment>
      <Button label="No" icon="pi pi-times" outlined onClick={hideDeleteProductsDialog} />
      <Button label="Yes" icon="pi pi-check" severity="danger" onClick={deleteSelectedProducts} />
    </React.Fragment>
  )

  const leftToolbarTemplate = () => {
    return (
      <div className="d-flex">
        <Button className={`${styles.actionBtn} me-2`} label="New" icon="pi pi-plus" severity="success" onClick={openNew} />

        {/* if editListName != EditListNames.USERS then show else no display */}
        {editListName !== EditListNames.USERS && (
          <Button
            className={`${styles.actionBtn} me-2`}
            label="Delete"
            icon="pi pi-trash"
            severity="danger"
            onClick={confirmDeleteSelected}
            disabled={!selectedProducts || !selectedProducts.length}
          />
        )}
        {(editListName === EditListNames.APPLICATION_FORMS || editListName === EditListNames.APPLICATION_FORM_REGISTERS) && (
          <Button
            className={styles.actionBtn}
            label="Update"
            icon="pi pi-refresh"
            severity="Primary"
            //refresh the data by calling refetch
            onClick={() => refetch()}
          />
        )}
      </div>
    )
  }

  const rightToolbarTemplate = () => {
    return <Button label="Export" icon="pi pi-upload" className={`p-button-help ${styles.actionBtn}`} onClick={exportCSV} />
  }

  const recordDialogFooter = (
    <React.Fragment>
      <Button className={`${styles.actionBtn} me-2`} label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button className={`${styles.actionBtn}`} label="Save" icon="pi pi-check" onClick={saveRecord} />
    </React.Fragment>
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error loading: {error.message}</p>

  const filteredDataList = dataList.filter((rowData) => rowData.name !== "superadmin").sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))

  return (
    <div className={`card p-fluid ${styles.container}`}>
      <Toolbar className="mb-4" start={leftToolbarTemplate} end={rightToolbarTemplate}></Toolbar>
      <DataTable
        ref={dt}
        value={filteredDataList}
        paginator
        rows={10}
        rowsPerPageOptions={[5, 10, 25, 50]}
        tableStyle={{ width: "100%" }}
        className="p-datatable"
        globalFilter={globalFilter}
        filterDisplay="menu"
        header={header}
        selection={selectedProducts}
        onSelectionChange={(e) => setSelectedProducts(e.value)}
      >
        <Column selectionMode="multiple" exportable={false}></Column>
        {fields.map(({ field, header }) => (
          <Column
            key={field}
            field={field}
            header={header}
            body={(rowData) => bodyTemplate(rowData, field)}
            style={{ whiteSpace: "nowrap" }}
            // onCellEditComplete={onCellEditComplete}
            sortable
          />
        ))}
        <Column body={actionBodyTemplate} exportable={false} style={{ minWidth: "12rem" }}></Column>
      </DataTable>
      <Toast ref={toast} />
      <Dialog
        visible={recordDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header={listName}
        modal
        className="p-fluid"
        footer={recordDialogFooter}
        onHide={handleCancel}
      >
        {fields.map(({ field, header }) => {
          if (field !== "createdAt" && field !== "updatedAt") {
            const fieldParts = field.split(".")
            const fieldValue = fieldParts.reduce((acc, part) => acc && acc[part], rowData)

            return (
              <div key={field} className="field">
                <label htmlFor={field}>{header}</label>
                {field === "tags" ? (
                  <InputTextarea
                    id={field}
                    value={Array.isArray(fieldValue) ? fieldValue.map((tag) => tag.name).join(", ") : ""}
                    onChange={(e) => handleInputChange(e, field)}
                    required
                    autoFocus
                    className="p-inputtext p-component"
                    rows={5}
                    cols={30}
                  />
                ) : field === "thumbnail.url" || field === "banner.url" ? (
                  <>
                    {!tempThumbnail && field === "thumbnail.url" && fieldValue && (
                      <img src={fieldValue} alt="thumbnail" style={{ maxWidth: "100%", marginBottom: "10px" }} />
                    )}
                    {tempThumbnail && field === "thumbnail.url" && (
                      <img src={tempThumbnail.objectURL} alt="thumbnail" style={{ maxWidth: "100%", marginBottom: "10px" }} />
                    )}
                    {!tempBanner && field === "banner.url" && fieldValue && (
                      <img src={fieldValue} alt="banner" style={{ maxWidth: "100%", marginBottom: "10px" }} />
                    )}
                    {tempBanner && field === "banner.url" && (
                      <img src={tempBanner.objectURL} alt="banner" style={{ maxWidth: "100%", marginBottom: "10px" }} />
                    )}
                    <FileUpload
                      mode="basic"
                      name={field}
                      auto
                      customUpload
                      uploadHandler={(e) => handleFileUpload(e, field.split(".")[0])}
                      chooseLabel="Choose"
                    />
                  </>
                ) : isSuperAdmin && field === "isAdmin" ? (
                  <Checkbox
                    className="ms-2"
                    inputId="ingredient1"
                    name="isAdmin"
                    onChange={(e) => handleInputChange(e, field)}
                    checked={fieldValue}
                  />
                ) : isSuperAdmin && field === "showCMS" ? (
                  <Checkbox
                    className="ms-2 mt-2"
                    inputId="ingredient2"
                    name="showCMS"
                    onChange={(e) => handleInputChange(e, field)}
                    checked={fieldValue}
                  />
                ) : isSuperAdmin && field === "showEsignature" ? (
                  <Checkbox
                    className="ms-2 mt-2"
                    inputId="ingredient3"
                    name="showEsignature"
                    onChange={(e) => handleInputChange(e, field)}
                    checked={fieldValue}
                  />
                ) : field === "finished" ? (
                  <Checkbox
                    className="ms-2 mt-2"
                    inputId="ingredient3"
                    name="finished"
                    onChange={(e) => handleInputChange(e, field)}
                    checked={fieldValue}
                    disabled
                  />
                ) : field === "password" ? (
                  <InputTextarea
                    placeholder="至少8位字符，可包含字母和數字"
                    id={field}
                    value={fieldValue}
                    onChange={(e) => handleInputChange(e, field)}
                    required
                    autoFocus
                    className="p-inputtext p-component"
                    rows={5}
                    cols={30}
                  />
                ) : field === "referenceNo" ? (
                  <InputTextarea
                    id={field}
                    value={fieldValue}
                    // onChange={(e) => handleInputChange(e, field)}
                    required
                    autoFocus
                    className="p-inputtext p-component"
                    rows={5}
                    cols={30}
                    readOnly
                  />
                ) : (
                  <InputTextarea
                    id={field}
                    value={fieldValue}
                    onChange={(e) => handleInputChange(e, field)}
                    required
                    autoFocus
                    className="p-inputtext p-component"
                    rows={5}
                    cols={30}
                  />
                )}
              </div>
            )
          }
          return null
        })}
        {errorMsg && <p style={{ color: "red" }}>{errorMsg}</p>}
      </Dialog>

      <Dialog
        visible={deleteProductDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductDialogFooter}
        onHide={hideDeleteProductDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3 pe-2" style={{ fontSize: "2rem" }} />
          {rowData && <span style={{ verticalAlign: "super" }}>Are you sure you want to delete it?</span>}
        </div>
      </Dialog>
      <Dialog
        visible={deleteProductsDialog}
        style={{ width: "32rem" }}
        breakpoints={{ "960px": "75vw", "641px": "90vw" }}
        header="Confirm"
        modal
        footer={deleteProductsDialogFooter}
        onHide={hideDeleteProductsDialog}
      >
        <div className="confirmation-content">
          <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
          {selectedProducts && <span>Are you sure you want to delete the selected records?</span>}
        </div>
      </Dialog>
    </div>
  )
}

export default EditView
